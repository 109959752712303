import axios from "axios";
import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";

function Login() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        email: e.currentTarget.email.value,
        password: e.currentTarget.password.value,
      };
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const res = await axios.post(`${API_URL}auth/signin`, payload);
      if (res.data) {
        localStorage.setItem("pet_user", JSON.stringify(res.data.user));
        localStorage.setItem("pet_token", JSON.stringify(res.data.accessToken));
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(res.data.user._id);
        }
        if (res.data.user.registerType === "User") {
          history.push("/app/user");
          setTimeout(() => {
            window.location.reload();
            window.ReactNativeWebView.postMessage("reload");
          }, 100);
        } else if (res.data.user.registerType === "Provider") {
          history.push("/app/pro");
          setTimeout(() => {
            window.location.reload();
            window.ReactNativeWebView.postMessage("reload");
          }, 100);
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t(error.response.data.message),
      });
    }
  };

  return (
    <div>
      <BreadCumb path={t("login")} />
      <div className="container">
        <section className="my-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-black">
                <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-xl-0 mt-15">
                  <form
                    className="contact-form"
                    onSubmit={handleLogin}
                    style={{ width: "23rem" }}
                  >
                    {/* <h3
                      className="fw-normal mb-3 pb-3"
                      style={{ letterSpacing: "1px" }}
                    >
                      Login
                    </h3> */}

                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="email">
                        {t("email")}
                      </label>
                      <input
                        required
                        type="email"
                        id="email"
                        className="form-control"
                      />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="password">
                        {t("login_password")}
                      </label>
                      <input
                        required
                        type="password"
                        id="password"
                        className="form-control"
                      />
                    </div>
                    <div className="pt-1 mb-4">
                      <button
                        className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                        type="submit"
                      >
                        {t("login")}
                      </button>
                    </div>
                    <p>
                      {t("login_link")}{" "}
                      <Link to="/register" className="link-info">
                        {t("register_here")}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
              <div className="align-items-center justify-content-center d-flex">
                <img
                  src="/img/blog/blog_details_img01.jpg"
                  alt="Register"
                  className="w-auto rounded"
                  style={{
                    objectFit: "cover",
                    objectPosition: "left",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Login;
